<template>
  <a-modal v-model="visible" width="80%" :maskClosable="false" title="报名名单" :footer="null">
    <data-list
      ref="dataList"
      class="enroll-list"
      :url="url"
      :query="query"
      :requestConfig="{ noTempleFilter: true }"
      entity-name="报名名单"
      show-row-num
      hide-show-detail
      hide-edit
      hide-delete
      :hide-action="!audit"
      :columns="columns"
      :search-key-type="searchKeyType"
      :row-selection="{ selectedRowKeys, onChange: onSelectChange, getCheckboxProps }"
      v-if="info"
    >
      <template v-slot:button>
        <a-button type="primary" @click="exportData" :loading="exporting">
          {{ exporting ? "正在导出" : "批量导出" }}
        </a-button>
        <a-dropdown :trigger="['click']" :disabled="selectedRowKeys.length == 0" v-if="audit">
          <a-button type="primary">一键审核</a-button>
          <a-menu slot="overlay">
            <a-menu-item :key="2" @click="batchAudit(2)">通过</a-menu-item>
            <a-menu-item :key="3" @click="batchAudit(3)">不通过</a-menu-item>
          </a-menu>
        </a-dropdown>
      </template>
      <template v-slot:query="{ form }">
        <a-form-model-item>
          <a-input v-model="form.name" placeholder="输入志工姓名查询" allowClear></a-input>
        </a-form-model-item>
        <a-form-model-item v-if="type == 3">
          <a-input v-model="form.id_card" placeholder="输入身份证号查询" allowClear></a-input>
        </a-form-model-item>
        <a-form-model-item>
          <a-input v-model="form.tel" placeholder="输入手机号码查询" allowClear></a-input>
        </a-form-model-item>
        <a-form-model-item v-if="type == 3">
          <a-select
            style="width: 180px;"
            placeholder="选择是否超时报名查询"
            @change="updateList"
            :options="flagOptions"
            v-model="form.is_timeout"
            allowClear
          >
          </a-select>
        </a-form-model-item>
        <a-form-model-item>
          <a-select
            style="width: 180px;"
            placeholder="选择审核状态查询"
            @change="updateList"
            :options="auditStatusOptions"
            v-model="form.status"
            allowClear
          >
          </a-select>
        </a-form-model-item>
      </template>
      <template v-slot:panel="{ form }" v-if="type == 3">
        <div class="query-switch">
          <div class="switch-item">
            <a-switch v-model="form.birth" @change="updateList" />
            <span class="switch-text">服务当天过生日</span>
          </div>
          <div class="switch-item">
            <a-switch v-model="form.interview" @change="updateList" />
            <span class="switch-text">符合面试条件</span>
          </div>
        </div>
      </template>
      <template v-slot:action="{ item }" v-if="audit">
        <a
          class="txt-btn"
          :class="item.status > 1 ? 'grey' : ''"
          v-for="option in auditOptions"
          :key="option.value"
          @click="auditItem(item, option)"
        >
          {{ option.label }}
        </a>
      </template>
    </data-list>
  </a-modal>
</template>

<script>
import {
  auditStatusOptions,
  auditOptions,
  flagOptions,
  dinnerReportOptions,
  classesTypeOptions,
  volunStatusOptions,
} from "../../../../common/hr/volunteer";
import { getTemple } from "../../../../common/js/storage";
import { downLoadBlobFile, getKeyTitle, getQuery, getTempleId, isEmpty } from "../../../../common/js/tool";

let temple = getTemple();

export default {
  name: "EnrollModal",
  model: {
    event: "change",
  },
  props: {
    value: {
      type: Boolean,
      value: false,
    },
    info: Object,
    type: Number, // 名单类型
  },
  data() {
    return {
      url: "/admin/volunteer-enroll-list",
      auditStatusOptions,
      auditOptions,
      flagOptions,
      exporting: false,
      currentItemId: 0,
      selectedRowKeys: [],
      selectedRows: [],
    };
  },
  computed: {
    visible: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("change", val);
      },
    },
    dataList() {
      return this.$refs.dataList;
    },
    audit() {
      // 志工活动 | 志工培训 线下 限制名额
      return this.type == 1 || (this.type == 2 && this.info?.form == 2 && this.info?.is_limit == 2) || this.type == 3;
    },
    query() {
      let res = `&sort=-id&expand=volunteer`;
      if (temple) {
        res += `&filter[volunteer_enroll_list.temple_id]=${temple}`;
      }
      if (this.info) {
        res += `&filter[content_id]=${this.info?.id}`;
      }
      if (this.type) {
        res += `&filter[type]=${this.type}`;
      }
      if (this.audit && this.type != 3) {
        res += `&filter[examine_status]=1`;
      }
      return res;
    },
    searchKeyType() {
      let obj = {
        name: 2,
        tel: 2,
        status: function(form, key) {
          let value = form[key];
          let res = "";
          if (!isEmpty(value)) {
            res = `&filter[volunteer_enroll_list.${key}]=${value}`;
          }
          return res;
        },
      };
      if (this.type == 3) {
        obj.id_card = 2;
        obj.birth = function(form, key) {
          let value = form[key];
          let res = "";
          if (value) {
            res = `&${key}=1`;
          }
          return res;
        };
        obj.interview = function(form, key) {
          let value = form[key];
          let res = "";
          if (value) {
            res = `&${key}=1`;
          }
          return res;
        };
      }
      return obj;
    },
    columns() {
      let columns = [
        {
          title: "志工姓名",
          customRender: (text) => {
            return <span>{text.volunteer?.name || "-"}</span>;
          },
        },
        {
          title: "身份证号",
          customRender: (text) => {
            return <span>{text.volunteer?.id_card || "-"}</span>;
          },
        },
        {
          title: "手机号码",
          customRender: (text) => {
            return <span>{text.volunteer?.tel || "-"}</span>;
          },
        },
        {
          title: "审核状态",
          dataIndex: "status",
          customRender: (text) => {
            return <span>{getKeyTitle(auditStatusOptions, text, "value", "label")}</span>;
          },
        },
      ];
      if (this.type == 3) {
        columns.splice(
          3,
          0,
          {
            title: "志工状态",
            customRender: (text) => {
              return <span>{getKeyTitle(volunStatusOptions, text.volunteer?.register_status, "value", "label")}</span>;
            },
          },
          {
            title: "是否超时报名",
            dataIndex: "is_timeout",
            customRender: (text) => {
              return <span>{getKeyTitle(flagOptions, text, "value", "label")}</span>;
            },
          },
          {
            title: "就餐报备",
            dataIndex: "dinner_report",
            customRender: (text) => {
              return (
                text?.split(",").map((value) => {
                  let target = dinnerReportOptions.find((item) => item.value == Number(value));
                  return <a-tag>{target.label}</a-tag>;
                }) || <span>-</span>
              );
            },
          },
          { title: "车牌报备", dataIndex: "numberplate", customRender: (text) => text || "-" },
          {
            title: "可服务班次",
            dataIndex: "serviceClass",
            customRender: (text) => {
              return (
                text?.map((classes) => {
                  let target = classesTypeOptions.find((item) => item.value == classes.type);
                  return <a-tag>{target.label}</a-tag>;
                }) || "-"
              );
            },
          }
        );
      }
      return columns;
    },
  },
  methods: {
    updateList() {
      this.dataList.updateList();
      this.selectedRowKeys = [];
      this.selectedRows = [];
    },
    exportData() {
      if (this.exporting) return;
      this.exporting = true;
      let searchKeyType = {
        name: 2,
        tel: 2,
        status: 2,
      };
      if (this.type == 3) {
        searchKeyType.id_card = 2;
        searchKeyType.is_timeout = 2;
        searchKeyType.birth = function(form, key) {
          let value = form[key];
          let res = "";
          if (value) {
            res = `&${key}=1`;
          }
          return res;
        };
        searchKeyType.interview = function(form, key) {
          let value = form[key];
          let res = "";
          if (value) {
            res = `&${key}=1`;
          }
          return res;
        };
      }
      let url = `/admin/volunteer-enroll-list/export?type=${this.type}&content_id=${this.info.id}`;
      url += getQuery.call(this.dataList, searchKeyType);
      url += getTempleId();
      this.$axios(url, { noTempleFilter: true, responseType: "blob" })
        .then((res) => {
          downLoadBlobFile(res, `${this.info.name}的报名名单`);
        })
        .finally(() => {
          this.exporting = false;
        });
    },
    onSelectChange(keys, rows) {
      this.selectedRowKeys = keys;
      this.selectedRows = rows;
    },
    getCheckboxProps(record) {
      return {
        props: {
          disabled: record.status != 1,
        },
      };
    },
    auditItem(item, option) {
      if (item.status > 1) {
        return;
      }
      this.$confirm({
        title: "提示",
        content: `确定${option.label}此志工吗？`,
        onOk: () => {
          const url = `${this.url}/${item.id}`;
          this.$axios({
            url,
            method: "PATCH",
            data: {
              status: option.value,
            },
          }).then(() => this.updateList());
        },
      });
    },
    batchAudit(status) {
      const keys = this.selectedRows.filter((row) => row.status == 1).map((row) => row.id); // 筛选待审核状态的数据
      if (keys.length > 0) {
        const url = "/admin/volunteer-enroll-list/batch-review";
        this.$axios({
          url,
          method: "POST",
          data: {
            ids: keys.join(","),
            status,
          },
          noTempleFilter: true,
        }).then((res) => {
          if (res.code == 0) {
            this.$message.success("一键审核成功");
            this.updateList();
          } else {
            this.$message.warning(res.msg || "一键审核失败");
          }
        });
      } else {
        this.$message.warning("请至少选择一条待审核状态的数据");
      }
    },
  },
};
</script>

<style lang="less" scoped>
.enroll-list {
  min-height: 600px;
}
.query-switch {
  display: flex;
  padding: 12px;
}
.switch-item {
  display: flex;
  align-items: center;
  &:not(:first-of-type) {
    margin-left: 24px;
  }
  .switch-text {
    margin-left: 12px;
  }
}
</style>
