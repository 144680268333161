<template>
  <div class="content md flex-box vertical">
    <data-list
      class="flex-grow"
      ref="dataList"
      :url="url"
      query="&sort=-id&expand=activityType,enrollNum"
      entity-name="活动"
      show-row-num
      hide-show-detail
      hide-edit
      hide-delete
      add-btn="添加活动"
      :columns="columns"
      :search-key-type="searchKeyType"
      :add-route="addRoute"
      :deal-list="dealList"
    >
      <template v-slot:query="{ form }">
        <a-form-model-item>
          <a-input v-model="form.name" placeholder="输入活动名称查询" allowClear></a-input>
        </a-form-model-item>
        <a-form-model-item>
          <a-select
            style="width: 180px;"
            placeholder="选择活动类型查询"
            @change="updateList"
            :options="typeOptions"
            v-model="form.activity_type_id"
            allowClear
          >
          </a-select>
        </a-form-model-item>
      </template>
      <template v-slot:action="{ item }">
        <div class="row-btn" @mousedown.stop @mouseup.stop>
          <a class="txt-btn" @click.stop="showDetail(item)" v-action:query>查看</a>
          <a class="txt-btn" @click.stop="editRow(item)" v-action:update v-if="item.status < 3">编辑</a>
          <a class="txt-btn" @click.stop="copyRow(item)">复制</a>
          <a
            class="txt-btn danger"
            @click.stop="delRow(item)"
            v-action:delete
            v-if="item.status == 1 || item.status == 5"
          >
            删除
          </a>
          <a class="txt-btn" @click.stop="showEnroll(item)">报名名单</a>
        </div>
      </template>
    </data-list>
    <EnrollModal v-if="eVisible" v-model="eVisible" :info="activity" :type="1"></EnrollModal>
  </div>
</template>

<script>
import { dataConvertOptions, getKeyTitle, timestampConvertString } from "../../../common/js/tool";
import { getActivityStatus, numberOptions } from "../../../common/hr/volunteer";
import EnrollModal from "./components/EnrollModal.vue";

export default {
  name: "ActivityList",
  components: {
    EnrollModal,
  },
  data() {
    return {
      url: "/admin/volunteer-activity",
      searchKeyType: {
        name: 1,
        title: 1,
      },
      columns: [
        { title: "活动名称", dataIndex: "name" },
        {
          title: "活动类型",
          customRender: (text) => {
            return <span>{text.activityType?.name || "-"}</span>;
          },
        },
        {
          title: "名额限制",
          dataIndex: "is_limit",
          customRender: (text) => {
            return <span>{getKeyTitle(numberOptions, text, "value", "label")}</span>;
          },
        },
        {
          title: "活动时间",
          width: 180,
          customRender: (text) => {
            let { activity_start_time, activity_end_time } = text;
            return (
              <span>
                {timestampConvertString(activity_start_time) + " ~ " + timestampConvertString(activity_end_time)}
              </span>
            );
          },
        },
        { title: "活动地点", dataIndex: "location" },
        { title: "招募总人数", dataIndex: "recruit_num" },
        { title: "报名人数", dataIndex: "enrollNum" },
        { title: "状态", dataIndex: "statusText" },
      ],
      typeOptions: [],
      addRoute: "ActivityAdd",
      activity: null,
      eVisible: false,
    };
  },
  computed: {
    action() {
      return this.$route.meta.action;
    },
    dataList() {
      return this.$refs.dataList;
    },
  },
  created() {
    this.getType();
  },
  methods: {
    getType() {
      return this.$axios({
        url: "/admin/volunteer-activity-type",
        method: "GET",
        params: {
          sort: "-id",
          pageSize: 9999,
        },
      }).then((res) => {
        this.typeOptions = dataConvertOptions(res.data);
      });
    },
    dealList(list, fn) {
      list?.forEach((item) => {
        getActivityStatus(item);
      });
      fn(list);
    },
    updateList() {
      this.dataList.updateList();
    },
    showDetail(item) {
      this.$router.push({
        name: "ActivityDetail",
        params: { id: item.id },
      });
    },
    editRow(item) {
      this.$router.push({
        name: "ActivityDetail",
        params: { id: item.id },
        query: { edit: 1 },
      });
    },
    delRow(item) {
      this.$confirm({
        title: "提示",
        content: `确定删除此志工活动吗？`,
        onOk: () => {
          this.$axios({
            url: `${this.url}/${item.id}`,
            method: "DELETE",
          }).then(() => {
            this.$message.success("删除成功");
            this.updateList();
          });
        },
      });
    },
    showEnroll(item) {
      this.activity = item;
      this.eVisible = true;
    },
    copyRow(item) {
      this.$router.push({
        name: "ActivityAdd",
        query: { id: item.id },
      });
    },
  },
};
</script>

<style lang="less" scoped>
.status-1 {
  color: @error-color;
}
.status-2 {
  color: @success-color;
}
.ant-calendar-picker {
  text-align: left;
}
</style>
